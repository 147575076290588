import invariant from 'invariant'
import React, { useEffect } from 'react'

import { StorageKey, StorageType } from '@pure/libs/hooks/useLocalStorageItemHelper'
import { useStorageItem } from '@pure/libs/hooks/useStorageItem'

import { getParkIdFromUrl } from '@web-js/libs/getParkIdFromUrl'

import { getSite } from '@pay/libs/DBApiHandler'
import { captureAndNotifyError } from '@pay/libs/ErrorHelper'
import { db } from '@pay/libs/FirebaseOptions'
import {
  DEFAULT_HOME_MACHINE_STATE,
  getStepForCLickingHeaderLogo as getStepForClickingHeaderLogo,
  HomeMachineState,
  HomeSteps,
  HomeViewProps
} from '@pay/libs/HomeHelper'
import { onPressContinue } from '@pay/libs/HomeMachine'
import { DefaultServices } from '@pay/libs/HomeMachineHelper'

import { HomeEnterRegNo } from './HomeEnterRegNo'
import { HomeUnpayedSession } from './HomeUnpayedSession'

export const Views: { [property in HomeSteps]: React.FC<HomeViewProps> } = {
  [HomeSteps.ENTER_REG_NO]: HomeEnterRegNo,
  [HomeSteps.UNPAYED_SESSIONS]: HomeUnpayedSession
}

export default function Home(props: { homeMachineState?: HomeMachineState; enableFetchParking?: boolean }) {
  const { enableFetchParking = true } = props
  const defaultHomeMachineState = props.homeMachineState || DEFAULT_HOME_MACHINE_STATE
  const { storageItem: homeMachineState, setStorageItem: setHomeMachineState } = useStorageItem<HomeMachineState>(
    StorageKey.SIGN_IN_STATE,
    defaultHomeMachineState,
    { storageType: StorageType.MEMORY_STORAGE }
  )

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const homeMachineViewProps: HomeViewProps = {
    isLoading,
    homeMachineState,
    onClickHeaderLogo: () => {
      const step = getStepForClickingHeaderLogo(homeMachineState)

      setHomeMachineState({ ...DEFAULT_HOME_MACHINE_STATE, step })
    },
    onClickBack: () => {
      setHomeMachineState({
        ...DEFAULT_HOME_MACHINE_STATE,
        data: { ...DEFAULT_HOME_MACHINE_STATE.data, site: homeMachineState.data.site }
      })
    },
    onPressContinue: (homeMachineState: HomeMachineState) =>
      Promise.resolve(setIsLoading(true))
        .then(() => onPressContinue(homeMachineState, DefaultServices))
        .then((homeMachineState) => setHomeMachineState(homeMachineState))
        .catch((e) => {
          captureAndNotifyError(e)
        })
        .finally(() => setIsLoading(false))
  }

  const component: React.FC<HomeViewProps> = Views[homeMachineViewProps.homeMachineState.step]
  invariant(component, `Cant find Onboarding Step for %s`, homeMachineViewProps.homeMachineState.step)

  useEffect(() => {
    if (!enableFetchParking) return
    setIsLoading(true)

    const siteId = getParkIdFromUrl(window.location.href)

    if (typeof siteId !== 'number') {
      setIsLoading(false)
      return
    }

    // only get parking once
    getSite(String(siteId), db)
      .then((site) => {
        if (!site) throw new Error('Failed to fetch site')

        setHomeMachineState({
          ...homeMachineState,
          data: { ...homeMachineState.data, site }
        })
      })
      .catch(() =>
        setHomeMachineState({
          ...homeMachineState,
          step: HomeSteps.ENTER_REG_NO
        })
      )
      .finally(() => setIsLoading(false))
  }, [])

  return React.createElement(component, homeMachineViewProps)
}
