import styled from '@emotion/styled'
import invariant from 'invariant'
import React from 'react'
import { useForm } from 'react-hook-form'

import { SlackWebhooks } from '@contracts/enums/SlackWebhooks'
import { Spacings } from '@contracts/enums/Spacings'
import { BorderRadiusesPx } from '@contracts/types/BorderRadixes'
import { Site } from '@contracts/types/Site'

import PayTexts from '@pure/assets/PayTexts'
import { getTotalParkingPriceForSession } from '@pure/libs/PaymentHelper'
import { isFreeSession } from '@pure/libs/SessionHelper'

import { getParkIdFromUrl } from '@web-js/libs/getParkIdFromUrl'
import { setSentryUser } from '@web-js/libs/SentryHelper'
import { slack } from '@web-js/libs/SlackHelperClient'

import { getFreeParkingDuration } from '@pay/helpers/HomeLandingHelper'
import useIsMobile from '@pay/hooks/useIsMobile'
import { useSite } from '@pay/hooks/useSite'
import { HomeViewProps } from '@pay/libs/HomeHelper'
import ImagesPay from '@pay/libs/Images'
import Texts from '@pay/libs/Texts'

import ActiveSessionWidget from './ActiveSessionWidget'
import ActiveSessionWidgetFree from './ActiveSessionWidgetFree'
import Box from './Box'
import Button from './Button'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { Layout } from './Layout'

export const HomeUnpayedSession = (props: HomeViewProps) => {
  const { homeMachineState } = props

  const { session } = homeMachineState.data

  const isFree = isFreeSession(session || undefined)
  const ActiveSessionWidgetComponent = isFree ? ActiveSessionWidgetFree : ActiveSessionWidget
  const shouldShowPayment = !isFree

  const siteId = getParkIdFromUrl(window.location.href)
  const { data: site } = useSite(String(siteId))
  const isMobile = useIsMobile()

  const freeTimeText = getFreeParkingDuration(site as Site)

  invariant(session, '!session')

  const formProps = useForm({ defaultValues: { regNo: '' } })

  const onSubmit = formProps.handleSubmit(async () => {
    await slack(`Pay button clicked for session: ${session?.id}`, SlackWebhooks.OPS_HIKER_PAY_QR)
    return props.onPressContinue({
      ...homeMachineState,
      data: { ...homeMachineState.data }
    })
  })

  React.useEffect(() => {
    if (!session.id) return
    setSentryUser({ id: session.id })
  }, [session.id])

  return (
    <Layout onClickHeaderLogo={props.onClickHeaderLogo}>
      <Box fullWidth fullPadding gap={Spacings.l}>
        {Boolean(site?.id) &&
          freeTimeText !== false &&
          isFreeSession(session) &&
          getTotalParkingPriceForSession(session) === 0 && (
            <PurpleContainer fullWidth spacing={Spacings.xs}>
              {
                <Box fullWidth direction="row" align="center" justify="space-between" fullPadding spacing={Spacings.s}>
                  <Box fullWidth gap={Spacings.xxs}>
                    <Box fullWidth direction="row" align="center" justify="space-between" gap={Spacings.m}>
                      <Box>
                        {typeof freeTimeText === 'string' ? (
                          <FigmaText textKey={Texts.textPaymentstextXhoursFreeParking} text={freeTimeText} />
                        ) : (
                          <FigmaText textKey={Texts.textPaymentsEnjoyFreeParking} text={freeTimeText?.header} />
                        )}
                      </Box>
                      <FigmaImageContainer imageKey={ImagesPay.stopwatchPurple} />
                    </Box>
                  </Box>
                </Box>
              }
            </PurpleContainer>
          )}

        <Box fullWidth gap={Spacings.s}>
          {isFree ? (
            <FigmaText textKey={PayTexts.textPaymentsUnpaidSessionH1} text={'Pågående gratisparkering'} />
          ) : (
            <FigmaText textKey={PayTexts.textPaymentsUnpaidSessionH1} />
          )}

          <ActiveSessionWidgetComponent session={session} site={site} />
          {shouldShowPayment && (
            <Button
              textKey={PayTexts.textButtonsPaymentSelectionButtonText}
              onClick={onSubmit}
              fullWidth
              loading={props.isLoading}
            />
          )}
        </Box>
      </Box>
    </Layout>
  )
}

const PurpleContainer = styled(Box)`
  border-radius: ${BorderRadiusesPx.rounded};
  background-color: rgba(94, 83, 225, 1);
`
