import 'react-toastify/dist/ReactToastify.css'
import './Sentry'
import './libs/Fonts'
import './libs/FirebaseOptions'

import { ThemeProvider } from '@mui/material'
import { useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import RoutePath from '@contracts/enums/RoutePath'

import { Context, useAppStateContext } from '@pure/libs/hooks/useAppState'

import ErrorBoundaryProvider from './components/ErrorBoundaryProvider'
import Home from './components/Home'
import { PaymentCallbackPage } from './components/PaymentCallbackPage'
import PermitContract from './components/PermitContract'
import PermitRegistrationForm from './components/PermitRegistrationForm'
import PermitSuccess from './components/PermitSuccess'
import PermitUsersForm from './components/PermitUsersForm'
import { ReceiptDetailPage } from './components/ReceiptDetailPage'
import Sandbox from './components/Sandbox'
import { MaterialTheme } from './libs/MaterialTheme'
import FortnoxOauthPage from './pages/FortnoxOAuthPage'
import { FortnoxOAuthRedirectPage } from './pages/FortnoxOAuthRedirectPage'
import { ReceiptPage } from './pages/ReceiptPage'
import { ReceiptSendEmailPage } from './pages/ReceiptSendEmailPage'
import { ReceiptSendSMSPage } from './pages/ReceiptSendSMSPage'

function App() {
  const [_loading, _setIsLoading] = useState<boolean>(false)
  const context = useAppStateContext()

  return (
    <ErrorBoundaryProvider>
      <ThemeProvider theme={MaterialTheme}>
        <Context.Provider value={context}>
          <BrowserRouter>
            <Routes>
              <Route path={RoutePath.SANDBOX} Component={Sandbox} />
              <Route path={RoutePath.RECEIPT_DETAIL} Component={ReceiptDetailPage} />
              {/** Used in roamer mobile app */}
              <Route path={RoutePath.RECEIPT} Component={ReceiptPage} /> {/** Used in receipt links */}
              <Route path={RoutePath.PAYMENT_CALLBACK} Component={PaymentCallbackPage} />
              {/** Used in receipt links */}
              <Route path={RoutePath.RECEIPT_SEND_SMS} Component={ReceiptSendSMSPage} />
              <Route path={RoutePath.RECEIPT_SEND_EMAIL} Component={ReceiptSendEmailPage} />
              {/** Customer facing app (:id the site id) */}
              <Route path="/:id" Component={Home} />
              <Route path={RoutePath.HOME} Component={Home} />
              <Route path={RoutePath.FORTNOX_OAUTH} Component={FortnoxOauthPage} />
              <Route path={RoutePath.FORTNOX_OAUTH_REDIRECT} Component={FortnoxOAuthRedirectPage} />
              <Route path="/:id/permit-contract" Component={PermitContract} />
              <Route path="/:id/permit-registration" Component={PermitRegistrationForm} />
              <Route path="/:id/permit-users" Component={PermitUsersForm} />
              <Route path="/permit-success" Component={PermitSuccess} />
            </Routes>
            <ToastContainer />
          </BrowserRouter>
        </Context.Provider>
      </ThemeProvider>
    </ErrorBoundaryProvider>
  )
}

export default App
