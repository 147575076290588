import styled from '@emotion/styled'

import { Spacings } from '@contracts/enums/Spacings'

import PayTexts from '@pure/assets/PayTexts'

import { HomeViewProps } from '@pay/libs/HomeHelper'

import Box from './Box'
import FigmaText from './FigmaText'
import { Layout } from './Layout'
import { SessionInformationContainer } from './SessionInformationContainer'
import { HardCodedTexts } from '../libs/HardCodedTexts'

export const HomeEnterRegNoEmptyState = (props: HomeViewProps) => {
  return (
    <Layout onClickHeaderLogo={props.onClickHeaderLogo}>
      <Box fullWidth fullPadding align="center" spacing={Spacings.xl} gap={Spacings.m}>
        <Box>
          <FigmaText textKey={PayTexts.textPaymentsQrCodePaymentEmptyStateText1} />
        </Box>
        <SessionInformationContainer fullWidth alignText="center">
          <FigmaText textKey={PayTexts.textPaymentsQrCodePaymentEmptyStateText2} />
        </SessionInformationContainer>
        <Box>
          <GoBackButton onClick={() => props?.onClickBack()}>
            <FigmaText textKey={HardCodedTexts.textGoBack} />
          </GoBackButton>
        </Box>
      </Box>
    </Layout>
  )
}

const GoBackButton = styled.button`
  all: unset;
  background-color: transparent;
  cursor: pointer;
`
