import { Box, Button, Checkbox, Container, FormControlLabel, FormGroup, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useIsDesktop } from '@pay/hooks/useIsMobile'

import { LayoutHeaderApp, LayoutHeaderDesktop } from './LayoutHeaderApp'

export default function PermitContract() {
  const isDesktop = useIsDesktop()
  const [hasAccepted, setHasAccepted] = useState(false)
  const navigate = useNavigate()

  const LayoutHeader = isDesktop ? LayoutHeaderDesktop : LayoutHeaderApp
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasAccepted(e.target.checked)
  }
  const handleSubmit = () => {
    if (hasAccepted) {
      navigate('/8004/permit-registration')
    }
  }

  return (
    <>
      <LayoutHeader onClickHeaderLogo={() => {}}></LayoutHeader>
      <Container maxWidth="sm">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography component="h1" variant="h5">
            Villkor Digitala Parkeringstillstånd
          </Typography>
          <Typography component="h3" variant="h6">
            Drifter på bolagen Nyfosa Samuel 1 fastighet AB samt Nyfosa Sigvald 6 fastighet AB
          </Typography>
          <Typography component="span" variant="body2">
            <ul>
              <li>
                För att vi skall kunna knyta tillståndet till ditt företag behöver du fylla i formuläret i nästa steg
                fullständigt.
              </li>
              <li>
                Parkeringstillstånd gäller ( i 30 dgr) för en kalendermånad i taget och avtalet förlängs automatiskt.
                Uppsägning av parkeringstillstånd görs via Drifter appen eller via e-post till foretag@drifterworld.se
                Inga uppsägningar hanteras via telefon. Till exempel: gör du uppsägningen mellan den 1 och 31 augusti,
                så löper ditt avtal till 30 september.
              </li>
              <li>
                För digitala tillstånd förbinder sig kunden att lägga till det/de registreringsnummer som denne nyttjar
                under fliken ”Mina bilar” i Drifter appen. Utebliven eller felaktig registrering kan föranleda att
                förhöjd parkeringsavgift utfärdas.
              </li>
              <li>Parkeringstillståndet gäller på den anläggningen som valts vid registrering.</li>
              <li>
                Genom att godkänna dessa villkor övergår din parkeringshantering till Drifters digitala
                parkeringstillstånd.
              </li>
              <li>Betalning erläggs i förskott för perioden.</li>
              <li>
                Tillstånd kan betalas via faktura. Drifter förbehåller sig rätten att ta ut en faktureringsavgift. Vid
                försenad eller utebliven betalning debiteras dröjsmålsränta enligt räntelagen.
              </li>
            </ul>
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox required checked={hasAccepted} onChange={onChange} />}
                label="Jag godkänner villkoren"
              />
            </FormGroup>
            <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
              Godkänn
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  )
}
