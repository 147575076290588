import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Spacings } from '@contracts/enums/Spacings'

import Texts from '@pure/assets/PayTexts'
import { ClientReceiptPayload, getSendReceiptRequest } from '@pure/libs/ReceiptHelper'
import { getFigmaTextFromVariable } from '@pure/libs/TextRepository'

import { ENTER_KEYCODE } from '@web-js/libs/Consts'
import { captureException } from '@web-js/libs/SentryHelper'

import { sendExpiredReceipt } from '@pay/libs/CloudFunctionsApiHandler'
import { success } from '@pay/libs/ToastHelper'
import { Variables } from '@pay/libs/Variables'

import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { HomeErrorMessage } from './HomeErrorMessage'
import { Layout } from './Layout'
import TextFieldForm from './TextFieldForm'

export const ReceiptExpiredUrl = ({ clientReceiptPayload }: { clientReceiptPayload?: ClientReceiptPayload }) => {
  const formProps = useForm({ defaultValues: { emailOrPhoneNo: '' } })
  const [error, setError] = useState<boolean>()
  const [loading, setLoading] = useState(false)

  const onSubmit = formProps.handleSubmit(({ emailOrPhoneNo }) => {
    const _clientReceiptPayload = clientReceiptPayload
    if (!_clientReceiptPayload) return setError(true)
    setLoading(true)
    const textKey = emailOrPhoneNo.includes('@')
      ? Texts.textNotificationsReceiptMailSent
      : Texts.textNotificationsReceiptSmsSent
    return sendExpiredReceipt(getSendReceiptRequest(emailOrPhoneNo, _clientReceiptPayload))
      .then(() =>
        success({
          textKey
        })
      )
      .catch((err) => {
        captureException(err)
        setError(true)
      })
      .finally(() => setLoading(false))
  })

  return (
    <Layout>
      <Box fullWidth fullPadding>
        <FigmaText textKey={Texts.textPaymentsReceiptReceiptSecurityLayerHeader} />
        <Box top spacing={Spacings.m} fullWidth>
          <FigmaTextWithStyle type="captionBold" text={getFigmaTextFromVariable(Variables['VariableID:1655:10657'])} />
          <Controller
            control={formProps.control}
            name="emailOrPhoneNo"
            render={({ field, fieldState }) => (
              <TextFieldForm
                {...field}
                fullWidth
                fieldState={fieldState}
                onKeyDown={(e) => e.keyCode === ENTER_KEYCODE && onSubmit(e)}
                autoFocus
              />
            )}
          />
          {!!error && (
            <Box top spacing={Spacings.xs}>
              <HomeErrorMessage textKey={Texts.textPaymentsReceiptReceiptSecurityLayerInputError} />
            </Box>
          )}
        </Box>
        <Box top fullWidth>
          <Button
            fullWidth
            loading={loading}
            onClick={onSubmit}
            text={getFigmaTextFromVariable(Variables['VariableID:1655:10660'])}
          />
        </Box>
      </Box>
    </Layout>
  )
}
