import dayjs from 'dayjs'
import invariant from 'invariant'

import { FreePricing } from '@contracts/types/FreePricing'
import { GetParkingSessionEventData } from '@contracts/types/GetParkingSessionData'
import { OffenseType } from '@contracts/types/OffenseType'
import {
  ParkingSession,
  ParkingSessionEvent,
  ParkingSessionEventOffense,
  ParkingSessionFreeTimeUsed
} from '@contracts/types/ParkingSession'
import { SessionEventName } from '@contracts/types/Session'
import {
  SessionPricingItem,
  Site,
  SitePricing,
  SitePricingConfiguration,
  SitePricingConfigurationAbsolute,
  SitePricingConfigurationAbsoluteItem
} from '@contracts/types/Site'

import { getArray } from '@pure/libs/Common'
import { OffenseTypes } from '@pure/libs/OffenseHelper'
import { SOMETHING_HIGH } from '@pure/libs/PaymentHelperOffense'

import { getParkingSessionOffenseEvent } from './getParkingSessionEventsHelperOffense'
import {
  getCurrentPricing,
  getCurrentSegment,
  getPricingForSlotPlaceCode,
  getRecordTime
} from './getParkingSessionHelper'
import {
  getDateRangeSitePricingConfigurationAbsoluteItem,
  getSitePricingTimeConfigurationItems
} from './getParkingSessionHelperPricing'

export const getCurrentSlotPlaceCode = (p: ParkingSession, now: string) =>
  _getCurrentEvent(p.slotEvents, now)?.place_code

export const getCurrentGateDeviceId = (p: ParkingSession, now: string) =>
  _getCurrentEvent(p.gateEvents, now)?.device_id?.toString()

export function _getCurrentEvent(obj: ParkingSession['slotEvents'], now: string) {
  const entries = Object.entries(obj || {}).filter(([moment]) => !dayjs(moment).isAfter(now))

  const entry = entries[entries.length - 1]

  return entry ? entry[1] : undefined
}

export function getMoments(activeSession: ParkingSession) {
  const now = dayjs(getRecordTime(activeSession.lastCameraEvent)).utc().format()

  // create moments with 1 minute interval starting from session start
  const diff = dayjs(now).diff(dayjs(activeSession.startedAt), 'minute')
  let moments = getArray(diff + 1).map((x, i) => dayjs(activeSession.startedAt).add(i, 'minute').utc().format())

  // make sure gate/slot events time is also part of moments
  if (activeSession.gateEvents) moments = moments.concat(Object.keys(activeSession.gateEvents))
  if (activeSession.slotEvents) moments = moments.concat(Object.keys(activeSession.slotEvents))
  // sort events to mix gate/slot events with other moments in time
  moments = moments.sort()

  // remove duplicates (if any)
  moments = [...new Set(moments)]

  return moments
}

export function getFreeParkingEvent({
  parkingSession,
  site,
  now,
  freeTimeUsed = {}
}: {
  parkingSession: ParkingSession
  site: Site
  now: string
  freeTimeUsed?: ParkingSessionFreeTimeUsed
}): ParkingSessionEvent | undefined {
  const startedAt = getLastPricingChange(parkingSession, site, now)
  const slotPlaceCode = getCurrentSlotPlaceCode(parkingSession, now)
  const segment = getCurrentSegment(parkingSession, site, now)

  const pricing = getPricingForSlotPlaceCode(slotPlaceCode, parkingSession, site, now)

  const { parking: sitePricingConfiguration } = pricing

  const isAbsolute = sitePricingConfiguration.type == 'absolute'
  const absoluteDailyFreeDurationMinutes =
    (sitePricingConfiguration as SitePricingConfigurationAbsolute).dailyFreeDurationMinutes || 0
  const hasAbsoluteDailyFreeDurationMinutes = isAbsolute && absoluteDailyFreeDurationMinutes > 0

  return getSitePricingTimeConfigurationItems({
    startedAt,
    site,
    endedAt: now,
    pricingConfiguration: sitePricingConfiguration
  }).reduce(
    (a, item) => {
      if (a) return a
      const { startedAt } = item
      const { freeDurationMinutes: freeDurationMinutesFromItem } = item
      const absolutePricingItem = item.item as SitePricingConfigurationAbsoluteItem

      // for absolute prcing prefer absolute daily free duration minutes over free duration minutes from item
      const freeDurationMinutes = hasAbsoluteDailyFreeDurationMinutes
        ? absoluteDailyFreeDurationMinutes
        : freeDurationMinutesFromItem

      if (!freeDurationMinutes) return undefined

      if (isAbsolute) {
        const { from, to } = getDateRangeSitePricingConfigurationAbsoluteItem(startedAt, absolutePricingItem, site)
        if (!isActiveEvent({ startedAt: from.format(), endedAt: to.format() }, now)) return undefined
      }

      const freeTimeUsedForSegment: number = Object.entries(freeTimeUsed).reduce(
        (a, [sessionsId, v]) =>
          a +
          Object.entries(v).reduce((a, [segmentId, v]) => {
            if (!segmentId) return a
            if (segmentId !== segment?.id) return a
            return a + v.freeMinutesUsed
          }, 0),
        0
      )

      const endedAt = dayjs(startedAt)
        .add(freeDurationMinutes, 'minute')
        .subtract(freeTimeUsedForSegment, 'minute')
        .utc()
        .format()

      if (!dayjs(endedAt).isAfter(startedAt)) return undefined

      if (!isActiveEvent({ startedAt, endedAt }, now)) return undefined

      return {
        name: SessionEventName.PARKING_FREE,
        pricingItems: [],
        startedAt,
        endedAt,
        segmentId: segment?.id,
        type: 'free'
      }
    },
    undefined as ParkingSessionEvent | undefined
  )
}

function getLastPricingChange(parkingSession: ParkingSession, site: Site, now: string) {
  const moments = getSlotAndGateMomentsForParkingSession(parkingSession)
  return moments.reduce((a, moment, i, arr) => {
    if (i === 0) return a
    if (dayjs(moment).isAfter(now)) return a

    const prev = arr[i - 1]
    const pricing = getCurrentPricing(parkingSession, site, moment)
    const prevPricing = getCurrentPricing(parkingSession, site, prev)

    if (pricing?.id !== prevPricing?.id) return moment

    return a
  }, parkingSession.startedAt)
}

export const getSlotAndGateMomentsForParkingSession = (parkingSession: ParkingSession): string[] =>
  [...Object.keys(parkingSession.slotEvents), ...Object.keys(parkingSession.gateEvents)].sort()

export function getOffenseEvent({
  site,
  parkingSession,
  vehicle,
  user,
  permits,
  now,
  currentCameraEvent,
  nextCameraEvent,
  cameraEvents
}: GetParkingSessionEventData): ParkingSessionEvent | undefined {
  return Object.keys(OffenseTypes).reduce(
    (acc, offenseType) => {
      if (acc) return acc
      const OffenseType = OffenseTypes[offenseType as OffenseType]

      // TODO WRITE TEST should return empty array if site[OffenseType.featureKey] is false
      if (site[OffenseType.featureKey] === false) return acc

      const event = getParkingSessionOffenseEvent({
        parkingSession,
        site,
        offenseType: offenseType as OffenseType,
        vehicle,
        user,
        permits,
        currentCameraEvent,
        nextCameraEvent,
        cameraEvents,
        now
      })

      if (event) acc = event

      return acc
    },
    undefined as ParkingSessionEvent | undefined
  )
}

export const startEvent = (event: ParkingSessionEvent, moment: string, place_code?: string): ParkingSessionEvent => {
  return {
    ...event,
    place_code,
    startedAt: dayjs(moment).utc().format()
  }
}

export const endEvent = (
  event: ParkingSessionEvent,
  moment: string,
  site: Site,
  parkingSession: ParkingSession
): ParkingSessionEvent => {
  const endedAt = dayjs(moment).utc().format()
  const { startedAt } = event
  invariant(startedAt, '!startedAt')
  const { offenseType } = event as ParkingSessionEventOffense
  const maxOffenseAmount = site[OffenseTypes[offenseType]?.maxAmountKey] || SOMETHING_HIGH

  // TODO Remove put in if below isntead to save computer cycles
  const place_code = getCurrentSlotPlaceCode(parkingSession, moment)

  // TODO WRITE TEST, should update place code if it does not exist
  if (!event.place_code && place_code) event.place_code = place_code

  // TODO WRITE TEST, should prioritize pricing on permit
  let pricing = getPricingForSlotPlaceCode(event.place_code, parkingSession, site, moment)

  if (event.type === 'permit') pricing = event?.permit?.pricing || FreePricing

  const pricingConfiguration = findPricingConfigurationItem(event, pricing)

  invariant(pricingConfiguration, '!pricingConfiguration')

  const pricingItems = getSitePricingTimeConfigurationItems({
    startedAt,
    site,
    endedAt,
    pricingConfiguration,
    enableFreeDuration: false
  }).reduce((a, item) => {
    // TODO WRITE TEST: should not take freeDuration into account when ending event (its handled when starting event)
    if (item.cost === 0) return a

    // TODO WRITE TEST: should not take freeDuration into account when ending event (its handled when starting event)
    if (event.type === 'free') return a

    if (event.type === 'offense' && offenseType && maxOffenseAmount < item.cost) item.cost = maxOffenseAmount

    a.push(item)
    return a
  }, [] as SessionPricingItem[])

  return {
    ...event,
    pricingItems,
    endedAt
  }
}

function findPricingConfigurationItem(
  startEvent: ParkingSessionEvent,
  sitePricing: SitePricing
): SitePricingConfiguration | undefined {
  const offenseType = (startEvent as ParkingSessionEventOffense).offenseType
  const offensePricingConfiguration = sitePricing.offense?.find((p) => p.offenseType === offenseType)

  return offensePricingConfiguration || sitePricing.parking
}

export const isActiveEvent = ({ startedAt, endedAt }: { startedAt?: string; endedAt?: string }, now: string) => {
  if (dayjs(now).isBefore(startedAt)) return false
  if (dayjs(now).isSame(endedAt)) return false
  if (dayjs(now).isAfter(endedAt)) return false

  return true
}
