import styled from '@emotion/styled'
import React from 'react'

import { Spacings } from '@contracts/enums/Spacings'
import { BorderRadiusesPx } from '@contracts/types/BorderRadixes'
import { Site } from '@contracts/types/Site'

import { Colors } from '@pure/assets/Colors'
import Images from '@pure/assets/PayImages'
import { getFigmaText } from '@pure/libs/TextRepository'

import { getParkIdFromUrl } from '@web-js/libs/getParkIdFromUrl'

import { getFreeParkingDuration } from '@pay/helpers/HomeLandingHelper'
import useIsMobile, { useIsDesktop } from '@pay/hooks/useIsMobile'
import { useSite } from '@pay/hooks/useSite'
import ImagesPay from '@pay/libs/Images'
import Texts from '@pay/libs/Texts'

import { AppStoreButtons } from './AppStoreButtons'
import Box from './Box'
import FigmaImage from './FigmaImage'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText, { FigmaTextProps } from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { LAYOUT_CHILDREN_MAX_WIDTH } from './HardcodedSizes'
import { LayoutHeaderDesktop } from './LayoutHeaderApp'
import Loader from './Loader'
import { StationInfoDrawer } from './StationInfoDrawer'

export const HomeLanding = () => {
  const isMobile = useIsMobile()
  const isDesktop = useIsDesktop()
  const siteId = getParkIdFromUrl(window.location.href)
  const [showStationInfo, setShowStationInfo] = React.useState(false)
  const { data: site, isLoading: isLoadingSite } = useSite(String(siteId))

  const freeTimeText = getFreeParkingDuration(site as Site)

  return (
    <Box fullWidth align="center">
      <StationInfoDrawer open={showStationInfo} onClose={() => setShowStationInfo(false)} site={site} />
      <LayoutHeaderDesktop onClickHeaderLogo={undefined as any} />
      <Box fullWidth style={{ maxWidth: isDesktop ? LAYOUT_CHILDREN_MAX_WIDTH : undefined }}>
        <Box fullWidth top align="center" left={isMobile} right={isMobile}>
          <PurpleContainer
            fullWidth
            fullPadding
            spacing={Spacings.xs}
            style={{
              backgroundColor:
                site?.id && site?.enablePayments && freeTimeText !== false ? 'rgba(94, 83, 225, 1)' : 'transparent'
            }}
          >
            <ParkingInformation
              direction="row"
              align="center"
              fullPadding
              spacing={Spacings.s}
              fullWidth
              gap={Spacings.s}
            >
              <FigmaImageContainer imageKey={ImagesPay.parkingPurple} />
              <Box>
                {isLoadingSite ? (
                  <Loader size={Spacings.s} />
                ) : (
                  <FigmaTextWithStyle
                    type="body1Regular"
                    text={site?.name ?? 'Site not found'}
                    Component={FigmaTextWithLinebreaks as (props: FigmaTextProps) => JSX.Element}
                  />
                )}
              </Box>
            </ParkingInformation>
          </PurpleContainer>
          {site && !site?.enablePayments && (
            <BlueInformationBox fullWidth>
              <Box fullWidth direction="row" align="center" gap={Spacings.s}>
                <FigmaImage imageKey={Images.info} />
                <Box gap={Spacings.xxs}>
                  <FigmaText textKey={Texts.textPaymentsQrPaymentCurrentlyFree} />
                  <FigmaText textKey={Texts.textPaymentsQrPaymentNoPaymentRequired} />
                </Box>
              </Box>
            </BlueInformationBox>
          )}
          {site?.enablePayments && (
            <Box top fullWidth spacing={Spacings.xs}>
              <Box fullWidth align="center" top bottom spacing={Spacings.xs} onClick={() => setShowStationInfo(true)}>
                <ClickableBox direction="row" align="center">
                  <FigmaImageContainer imageKey={ImagesPay.informationIconDark} />
                  <Box left spacing={Spacings.xxs}>
                    <FigmaText textKey={Texts.textPaymentsPricesAndParkingInformation} />
                  </Box>
                </ClickableBox>
              </Box>
            </Box>
          )}
          <Box fullWidth position="absolute" style={{ bottom: '10%' }}>
            <Box fullWidth align="center">
              <FigmaTextWithStyle
                text={getFigmaText(Texts.textPaymentsQrPaymentMarketTheApp)}
                type="body2Regular"
                color="purple5"
              />
              <Box top spacing={Spacings.s} position="relative">
                <Box position="absolute" style={{ left: `-${Spacings.xl}`, top: `${Spacings.xs}` }}>
                  <FigmaImageContainer imageKey={Images.headerArrow} />
                </Box>
                <AppStoreButtons width={Images.homeLandingAppStore.width} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const PurpleContainer = styled(Box)`
  border-radius: ${BorderRadiusesPx.rounded};
`

const ParkingInformation = styled(Box)`
  background: var(--surface-surface-information, ${Colors.purpleSurface});
  border-radius: var(--radius-radius-lighly-rounded, ${BorderRadiusesPx.lightlyRounded});
`

const ClickableBox = styled(Box)`
  cursor: pointer;
`

const BlueInformationBox = styled(Box)`
  margin-top: ${Spacings.s};
  padding: ${Spacings.s};
  border: 1px solid rgba(35, 145, 207, 1);
  border-radius: ${BorderRadiusesPx.minimum};
  background-color: rgba(233, 245, 252, 1);
  box-shadow: 0px 4px 8px 0px rgba(53, 51, 85, 0, 0.1);
`
