import { Box, Container, Typography } from '@mui/material'

import { useIsDesktop } from '@pay/hooks/useIsMobile'

import { LayoutHeaderApp, LayoutHeaderDesktop } from './LayoutHeaderApp'

export default function PermitSuccess() {
  const isDesktop = useIsDesktop()

  const LayoutHeader = isDesktop ? LayoutHeaderDesktop : LayoutHeaderApp
  return (
    <>
      <LayoutHeader onClickHeaderLogo={() => {}}></LayoutHeader>
      <Container maxWidth="sm">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        ></Box>
        <Typography component="h3" variant="h6">
          Vi har tagit emot din ansökan, tack!
        </Typography>
      </Container>
    </>
  )
}
