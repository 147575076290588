import useQueryParams, { PaymentCallbackQueryParams } from 'pure/libs/useQueryParams'
import React from 'react'

import { ONE_SECOND } from '@consts/DateConsts'

import Texts from '@pure/assets/PayTexts'
import { decryptClientReceiptPayload } from '@pure/libs/ReceiptHelper'

import { useHikerSession } from '@pay/hooks/useHikerSession'
import useIsMobile from '@pay/hooks/useIsMobile'
import { HomeSteps } from '@pay/libs/HomeHelper'
import { error } from '@pay/libs/ToastHelper'

import Box from './Box'
import Home from './Home'
import { LoadingPage } from './LoadingPage'
import { Receipt } from './Receipt'
import { ReceiptButtonsPublic } from './ReceiptButtonsPublic'

// Receipt page used by swish stripe
export const PaymentCallbackPage = () => {
  const { hash } = useQueryParams<PaymentCallbackQueryParams>()
  const clientReceiptPayload = decryptClientReceiptPayload(hash)
  const isMobile = useIsMobile()

  if (!clientReceiptPayload) return null

  const { sessionId, regNo } = clientReceiptPayload

  const { data: session, isLoading: isLoadingSession } = useHikerSession(sessionId.toString())
  const isPayed = session?.payment?.status === 'paid'

  React.useEffect(() => {
    if (isLoadingSession) return
    if (isPayed === false) error({ textKey: Texts.textNotificationsNotificationPaymentErrorReturned })
  }, [isLoadingSession, isPayed])

  React.useEffect(() => {
    if (isPayed === undefined) setTimeout(() => window.location.reload(), ONE_SECOND * 2) // this is needed to fix swish ux
  }, [isPayed])

  if (!session) return null

  if (isLoadingSession) return <LoadingPage LayoutComponent={Box} />

  if (isPayed === true)
    return (
      <Receipt
        clientReceiptPayload={clientReceiptPayload}
        session={session}
        buttonElement={<ReceiptButtonsPublic session={session} />}
      />
    )

  if (isPayed === false)
    return (
      <Home
        enableFetchParking={false}
        homeMachineState={{
          step: HomeSteps.ENTER_REG_NO,
          data: { regNo, isMobile }
        }}
      />
    )

  return <LoadingPage LayoutComponent={Box} />
}
