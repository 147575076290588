import styled from '@emotion/styled'
import { useId } from 'react'
import React from 'react'
import { Controller } from 'react-hook-form'
import * as Yup from 'yup'

import { Spacings } from '@contracts/enums/Spacings'
import { BorderRadiusesPx } from '@contracts/types/BorderRadixes'

import { Colors } from '@pure/assets/Colors'
import pricingData from '@pure/assets/data/custom-site-pricing.json'
import Texts from '@pure/assets/PayTexts'
import { getFigmaText, getFigmaTextFromVariable } from '@pure/libs/TextRepository'

import { useForm } from '@pay/hooks/useForm'
import { HomeViewProps } from '@pay/libs/HomeHelper'
import ImagesPay from '@pay/libs/Images'
import PayTexts from '@pay/libs/Texts'
import { RequiredTextValidation } from '@pay/libs/ValidationHelper'
import { Variables } from '@pay/libs/Variables'

import Box from './Box'
import Button from './Button'
import CustomPricing from './CustomPricing'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText, { FigmaTextProps } from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { HomeEnterRegNoEmptyState } from './HomeEnterRegNoEmptyState'
import { HomeLanding } from './HomeLanding'
import { Layout } from './Layout'
import { RegistrationNumberField } from './RegistrationNumberField'
import { StationInfoDrawer } from './StationInfoDrawer'

const ValidationSchema = Yup.object()
  .shape({
    regNo: RequiredTextValidation('Enter reg no')
  })
  .required()

export const HomeEnterRegNo = (props: HomeViewProps) => {
  const { homeMachineState } = props
  const [isPricingModalVisible, setIsPricingModalVisible] = React.useState(false)

  const formProps = useForm(ValidationSchema, { defaultValues: { regNo: props.homeMachineState.data.regNo || '' } })
  const inputId = useId()

  const onSubmit = formProps.handleSubmit(({ regNo }) => {
    const trimmedRegNo = regNo.trim()
    return props.onPressContinue({ ...homeMachineState, data: { ...homeMachineState.data, regNo: trimmedRegNo } })
  })

  const site = homeMachineState.data?.site

  if (homeMachineState.data.session === null) {
    return <HomeEnterRegNoEmptyState {...props} />
  }

  if (!site || !site.enablePayments) {
    return <HomeLanding />
  }

  return (
    <>
      <StationInfoDrawer open={isPricingModalVisible} onClose={() => setIsPricingModalVisible(false)} site={site} />
      <Layout onClickHeaderLogo={props.onClickHeaderLogo}>
        <Box fullWidth fullPadding>
          <ParkingInformation
            direction="row"
            align="center"
            fullPadding
            spacing={Spacings.s}
            fullWidth
            gap={Spacings.s}
          >
            <FigmaImageContainer imageKey={ImagesPay.parkingPurple} />
            <FigmaTextWithStyle
              type="body1Regular"
              text={site?.name ?? 'Site not found'}
              Component={FigmaTextWithLinebreaks as (props: FigmaTextProps) => JSX.Element}
            />
          </ParkingInformation>
        </Box>
        <Box fullWidth fullPadding>
          <form onSubmit={onSubmit}>
            <label htmlFor={inputId}>
              <FigmaTextWithStyle
                type="captionBold"
                text={getFigmaText(Texts.textPaymentsQrCodePaymentEnterRegNo)}
                color="neutral7"
              />
            </label>
            <Box top spacing={Spacings.m} fullWidth>
              <Controller
                control={formProps.control}
                name="regNo"
                render={({ field, fieldState }) => (
                  <RegistrationNumberField
                    {...field}
                    id={inputId}
                    fullWidth
                    fieldState={fieldState}
                    autoFocus
                    placeholderTextKey={Texts.textPaymentsQrCodePaymentRegNoHelperText}
                  />
                )}
              />
            </Box>
            <Box top fullWidth>
              <Button
                type="submit"
                fullWidth
                loading={props.isLoading}
                text={getFigmaTextFromVariable(Variables['VariableID:734:11919'])}
              />
            </Box>
          </form>

          {site && site.enablePayments && (
            <Box top fullWidth spacing={Spacings.m}>
              {pricingData[site.id] ? (
                <CustomPricing site={pricingData[site.id]} />
              ) : (
                <Box
                  fullWidth
                  align="center"
                  top
                  bottom
                  spacing={Spacings.xs}
                  onClick={() => setIsPricingModalVisible(true)}
                >
                  <ClickableBox direction="row" align="center">
                    <FigmaImageContainer imageKey={ImagesPay.informationIconDark} />
                    <Box left spacing={Spacings.xxs}>
                      <FigmaText textKey={PayTexts.textPaymentsPricesAndParkingInformation} />
                    </Box>
                  </ClickableBox>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Layout>
    </>
  )
}

const ParkingInformation = styled(Box)`
  background: var(--surface-surface-information, ${Colors.purpleSurface});
  border-radius: var(--radius-radius-lighly-rounded, ${BorderRadiusesPx.lightlyRounded});
`

const ClickableBox = styled(Box)`
  cursor: pointer;
`
